<template>
  <div>
    <!-- 顶部 -->
    <el-row>
      <el-col :span="19">
        <el-button type="primary" @click="addMemberDialogVisible = true">新增会员</el-button>
      </el-col>

      <el-col :span="5" style="display: flex">
        <el-input
          placeholder="支持查询 编号 / 会员名称 / 手机号"
          prefix-icon="el-icon-search"
          v-model.trim="queryStr"
          clearable
          @keyup.enter.native="search"
        >
          <el-button slot="append" icon="el-icon-search" type="success" @click="search"></el-button>
        </el-input>
      </el-col>
    </el-row>
    <!-- 表格 -->
    <div style="margin: 15px 0">
      <el-table
        id="memberList"
        ref="memberListRef"
        @expand-change="handlExpandChange"
        :data="tableData"
        stripe
        height="43rem"
        style="width: 100%"
      >
        <!-- 展开列 -->
        <el-table-column align="center" type="expand">
          <template slot-scope="props">
            <div v-if="props.row.consumeList && props.row.consumeList.length !== 0">
              <template v-for="(item, index) in props.row.consumeList">
                <el-descriptions
                  :key="index"
                  :title="`${item.consumeDate} ---- 第 ${index + 1} 次消费`"
                  size="small"
                >
                  <el-descriptions-item label="用户名">{{
                    props.row.userName
                  }}</el-descriptions-item>
                  <el-descriptions-item label="消费项目">
                    <template v-if="item.consumePoj.split(',').length !== 0">
                      <el-tag
                        v-for="consumeName in item.consumePoj.split(',')"
                        :key="consumeName"
                        size="mini"
                        style="margin: 0 5px 0 0"
                      >
                        {{ consumeName }}
                      </el-tag>
                    </template>
                    <el-tag v-else>{{ item.consumePoj }}</el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item label="消费金额">{{
                    item.consumeMoney
                  }}</el-descriptions-item>

                  <el-descriptions-item label="备注">
                    <el-input
                      v-if="item.consumeNotes"
                      placeholder="消费备注"
                      v-model="item.consumeNotes"
                      :disabled="true"
                    />
                    <el-input
                      v-else
                      placeholder="消费备注"
                      v-model="item.consumePoj"
                      :disabled="true"
                    />
                  </el-descriptions-item>
                </el-descriptions>
              </template>
            </div>
            <el-empty v-else description="暂无消费记录"></el-empty>
          </template>
        </el-table-column>
        <!-- 信息列 -->
        <template v-for="item in columnList">
          <el-table-column
            align="center"
            v-if="item.prop === 'id'"
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            sortable
          >
            <template slot-scope="scope">
              <span v-html="fontStyle(scope.row.id + '')"></span>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            v-else-if="item.prop === 'balance'"
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            sortable
          />

          <el-table-column
            align="center"
            v-else-if="item.prop === 'userName'"
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
          >
            <template slot-scope="scope">
              <span v-html="fontStyle(scope.row.userName + '')"></span>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            v-else-if="item.prop === 'phoneNum'"
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
          >
            <template slot-scope="scope">
              <span v-html="fontStyle(scope.row.phoneNum + '')"></span>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            v-else-if="item.prop === 'type'"
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
          >
            <template slot-scope="scope">
              <span>{{ cardType[scope.row.type] }}</span>
            </template>
          </el-table-column>

          <!-- 消费次数列 -->
          <el-table-column
            align="center"
            v-else-if="item.prop === 'consumeCount'"
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            sortable
            :min-width="item.minWidth"
          />

          <el-table-column
            align="center"
            v-else
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            :min-width="item.minWidth"
          />
        </template>

        <!-- 操作列 -->
        <el-table-column align="center" label="操作" width="350">
          <template slot-scope="scope">
            <!-- <el-button size="mini" icon="el-icon-view" round>查看</el-button> -->
            <el-button
              size="mini"
              type="info"
              icon="el-icon-edit"
              round
              @click="showEditDrawer(scope.row)"
              >编 辑</el-button
            >
            <el-button
              size="mini"
              type="success"
              icon="iconfont icon-xiaofeijilu"
              round
              @click="showConsumeRecord(scope.row)"
              >划 卡</el-button
            >
            <!-- <el-button size="mini" type="warning" icon="iconfont icon-chongzhi" round>充值</el-button> -->

            <el-popover
              placement="bottom"
              :title="scope.row.userName + '充值'"
              width="200"
              trigger="click"
            >
              <template>
                <el-row :gutter="10">
                  <el-col>
                    <el-input
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      v-model.number="topUpMoney"
                      placeholder="请输入充值金额"
                      size="mini"
                    />
                  </el-col>
                </el-row>
                <el-row style="margin-top: 10px" type="flex" justify="end">
                  <el-button type="primary" size="mini" @click="memberTopUp(scope.row)"
                    >确 定</el-button
                  >
                </el-row>
              </template>
              <el-button
                size="mini"
                type="warning"
                icon="iconfont icon-chongzhi"
                round
                slot="reference"
                >充 值</el-button
              >
            </el-popover>

            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              round
              @click="deleteMember(scope.row)"
              >删 除</el-button
            >
          </template>
        </el-table-column>
        <!-- 过滤列 -->

        <!-- <el-table-column align="center" label="过滤" :filters="filters" width="40" min-width="40" filter-placement="bottom" column-key="filter">
          <template slot="header">
            <i slot="reference" class="el-icon-setting" style="cursor: pointer;"></i>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      v-show="!isSearchTab"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="sizeList"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 新增会员弹框 -->
    <el-dialog
      class="add_member_dialog"
      title="新增会员"
      :visible.sync="addMemberDialogVisible"
      width="30%"
      :before-close="handleClose"
      :modal="true"
      append-to-body
      center
    >
      <!-- <span class="tips" style="">*为必填项</span> -->
      <!-- 新增会员表单 -->
      <el-form
        :model="addMemberFrom"
        :rules="addMemberRules"
        ref="addMemberFromRef"
        label-position="left"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="会员名称" prop="userName">
          <el-input placeholder="请输入会员名称" v-model="addMemberFrom.userName"></el-input>
        </el-form-item>
        <el-form-item label="办卡日期">
          <el-date-picker
            v-model="addMemberFrom.createTime"
            align="right"
            type="date"
            placeholder="请选择办卡日期 不选择默认为当天日期"
            :picker-options="pickerOptions"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy 年 MM 月 dd 日"
          />
        </el-form-item>
        <el-form-item label="手机号">
          <el-input placeholder="请输入会员手机号" v-model="addMemberFrom.phoneNum"></el-input>
        </el-form-item>
        <el-form-item label="办卡金额" prop="money">
          <el-input placeholder="请输入办卡金额" v-model.number="addMemberFrom.money"></el-input>
        </el-form-item>
      </el-form>
      <!-- 操作按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFrom('addMemberFromRef')">重 置</el-button>
        <el-button type="primary" @click="addMember">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑会员信息弹框 -->
    <el-drawer
      :visible.sync="editDrawerStatus"
      direction="rtl"
      :before-close="handleClose"
      append-to-body
      center
      :modal="false"
      :show-close="false"
    >
      <template slot="title">
        <h3 style="text-align: center">
          会员信息
          <span style="color: #000; font-size: 26px">{{ activeEditMemberInfo.userName }}</span>
        </h3>
      </template>
      <!-- 修改会员表单 -->
      <el-form
        style="margin: 20px 50px"
        :model="activeEditMemberInfo"
        :rules="editMemberRules"
        ref="editMemberFromRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="会员名称" prop="userName">
          <el-input placeholder="请输入会员名称" v-model="activeEditMemberInfo.userName"></el-input>
        </el-form-item>
        <el-form-item label="办卡日期">
          <el-input
            disabled
            placeholder="请输入会员名称"
            v-model="activeEditMemberInfo.createTime"
          ></el-input>
          <el-date-picker
            class="dataPickerBox"
            size="mini"
            v-model="changeCreateTime"
            type="date"
            clear-icon=""
            prefix-icon="el-icon-date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy 年 MM 月 dd 日"
            @change="changeDate"
          />
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            placeholder="请输入会员手机号"
            v-model="activeEditMemberInfo.phoneNum"
          ></el-input>
        </el-form-item>
        <el-form-item label="会员卡类型">
          <el-select v-model="activeEditMemberInfo.type" placeholder="请选择会员卡类型">
            <el-option
              v-for="item in cardTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <el-input placeholder="请输入会员手机号" v-model="activeEditMemberInfo.type"></el-input> -->
        </el-form-item>
      </el-form>
      <div style="margin: 0 50px; display: flex; justify-content: end">
        <el-button @click="editDrawerStatus = false">取消</el-button>
        <el-button type="primary" @click="editMemberInfo()">保存</el-button>
      </div>
    </el-drawer>

    <!-- 划卡对话框 -->
    <el-dialog
      class="add_member_dialog"
      :title="addConsumeMemberInfo.userName + ' 划卡'"
      :visible.sync="addConsumeDialogVisible"
      width="30%"
      :before-close="handleClose"
      :modal="true"
      append-to-body
      center
    >
      <!-- <span class="tips" style="">*为必填项</span> -->
      <!-- 划卡对话框 -->
      <el-form
        :model="consumeRecordFrom"
        :rules="consumeRecordFromRules"
        ref="addConsumeFromRef"
        label-position="left"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="消费项目" prop="consumePoj">
          <el-select
            v-if="!recordConsumeStatus"
            v-model="consumePojList"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="请选择消费项目"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            v-else
            placeholder="请输入消费项目"
            v-model="consumeRecordFrom.consumePoj"
            @input="handleConsumePoj"
          ></el-input>
          <el-switch
            v-model="recordConsumeStatus"
            inactive-text="筛选消费项目"
            active-text="主动备注消费项目"
          />
        </el-form-item>
        <!-- <el-form-item label="消费日期">
          <el-date-picker v-model="addConsumeMemberInfo.createTime" align="right" type="date" placeholder="请选择办卡日期 不选择默认为当天日期" :picker-options="pickerOptions" format="yyyy 年 MM 月 dd 日" value-format="yyyy 年 MM 月 dd 日" />
        </el-form-item> -->
        <el-form-item label="消费金额" prop="consumeMoney">
          <el-input
            placeholder="请输入消费金额"
            v-model.number="consumeRecordFrom.consumeMoney"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            placeholder="请输入备注信息"
            v-model="consumeRecordFrom.consumeNotes"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 操作按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addConsumeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addConsumeRecord">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import apis from '@/apis/apis'
export default {
  data() {
    return {
      cardType: { 1: '会员卡', 2: '剪发卡', 3: '洗头卡' },
      cardTypeList: [
        { value: 1, label: '会员卡' },
        { value: 2, label: '剪发卡' },
        { value: 3, label: '洗头卡' },
      ],
      // 是否是搜索表格
      isSearchTab: false,
      visible: false,
      // 日期选择快捷功能
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            },
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            },
          },
        ],
      },
      queryStr: '',
      tableData: [],
      // column配置
      columnList: [
        { label: '编号', prop: 'id', width: 'auto' },
        { label: '会员名称', prop: 'userName', width: 'auto' },
        {
          label: '办卡时间',
          prop: 'createTime',
          width: 'auto',
          minWidth: '180',
        },
        { label: '手机号', prop: 'phoneNum', width: 'auto', minWidth: '150' },
        { label: '卡金/次数', prop: 'money', width: 'auto' },
        { label: '余额/次数', prop: 'balance', width: 'auto' },
        {
          label: '消费次数',
          prop: 'consumeCount',
          width: 'auto',
          minWidth: '90',
        },
      ],
      // 过滤项
      filters: [
        { text: '编号', value: 'id' },
        { text: '会员名称', value: 'userName' },
        { text: '办卡时间', value: 'createTime' },
        { text: '手机号', value: 'phoneNum' },
        { text: '卡金', value: 'money' },
        { text: '余额', value: 'balance' },
        { text: '消费次数', value: 'consumeCount' },
      ],
      page: 1,
      pageSize: 10,
      total: 0,
      sizeList: [5, 10, 20, 30, 50, 100, 200],
      // 新增会员对话框状态
      addMemberDialogVisible: false,
      // 新增会员信息
      addMemberFrom: {
        userName: '',
        createTime: '',
        phoneNum: 0 || '',
        money: 0 || '',
        balance: 0 || '',
        consumeList: [],
      },
      // 新增会员表单校验规则
      addMemberRules: {
        userName: [
          { required: true, message: '请输入会员名称', trigger: 'blur' },
          {
            min: 2,
            max: 18,
            message: '长度在 2 到 18 个字符',
            trigger: 'blur',
          },
        ],
        money: [
          { required: true, message: '请输入办卡金额', trigger: 'blur' },
          { type: 'number', message: '办卡金额必须为数字', trigger: 'blur' },
        ],
      },
      // 编辑会员抽屉状态
      editDrawerStatus: false,
      // 当前编辑会员信息
      activeEditMemberInfo: {},
      editMemberRules: {
        userName: [
          { required: true, message: '请输入会员名称', trigger: 'blur' },
          {
            min: 2,
            max: 18,
            message: '长度在 2 到 18 个字符',
            trigger: 'blur',
          },
        ],
      },
      // 要修改的时间
      changeCreateTime: '',
      // 划卡对话框状态
      addConsumeDialogVisible: false,
      // 当前划卡用户信息
      addConsumeMemberInfo: {},
      // 消费项目数组
      consumePojList: [],
      // 消费项目
      options: [
        {
          value: '剪发',
          label: '剪发',
        },
        {
          value: '修面',
          label: '修面',
        },
        {
          value: '洗脸',
          label: '洗脸',
        },
        {
          value: '敷面',
          label: '敷面',
        },
        {
          value: '洗头',
          label: '洗头',
        },
        {
          value: '染头',
          label: '染头',
        },
        {
          value: '烫头',
          label: '烫头',
        },
      ],
      // 记录消费项目方式
      recordConsumeStatus: false,
      // 当前消费记录表
      consumeRecordFrom: {
        consumePoj: '',
        consumeMoney: 0 || '',
        consumeDate: '',
        consumeNotes: '',
      },
      consumeRecordFromRules: {
        consumePoj: [{ required: true, message: '请 输入/选择 消费项目', trigger: 'blur' }],
        consumeMoney: [
          { required: true, message: '请输入消费金额', trigger: 'blur' },
          { type: 'number', message: '消费金额必须为数字', trigger: 'blur' },
        ],
      },
      // visible: false
      // 充值金额
      topUpMoney: 0 || '',
    }
  },
  created() {
    this.pageSize = window.localStorage.getItem('pageSize') - 0 || 10
    this.getMemberList()
  },
  methods: {
    // 搜索高亮
    fontStyle(val) {
      if (val.indexOf(this.queryStr) !== -1 && !!this.queryStr.trim().length) {
        return (val = val.replaceAll(
          this.queryStr,
          `<font color=red weight=600>${this.queryStr}</font>`
        ))
      } else {
        return val
      }
    },
    async handleDeleteMember(id) {
      try {
        const pwd = prompt('请输入密码, 验证身份')
        if (!pwd || pwd !== '1314') return this.$message.error('密码错误, 身份校验失败!')
        const params = { id: id - 0 }
        const { data: res } = await this.$DELETE(apis.member.deleteMember, {
          params,
        })
        const { code, message } = res
        if (code !== 200 && message !== '成功') return
        this.$notify.success({ title: '成功', message: '删除成功' })
        this.getMemberList()
      } catch (error) {
        console.log(error)
      }
    },
    // 删除会员
    deleteMember(row) {
      this.$confirm(`是否删除会员 ${row.userName}?`, '删除会员', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning',
      })
        .then(() => {
          this.handleDeleteMember(row.id)
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 会员充值
    async memberTopUpMoney(id) {
      try {
        const params = { id, topUp: this.topUpMoney }
        const { data: res } = await this.$POST(apis.member.memberTopUp, params)
        const { code, message } = res
        if (code !== 200 && message !== '成功') return this.$message.error(message)

        this.$notify.success({
          title: '成功',
          message: '充值成功',
        })
        this.getMemberList()
        this.topUpMoney = ''
      } catch (error) {
        console.log(error)
      }
    },
    // 会员充值确认弹框
    memberTopUp(row) {
      if (!this.topUpMoney || this.topUpMoney.constructor !== Number) return
      this.$confirm(`是否对会员 ${row.userName} 充值 ${this.topUpMoney} ?`, '充值提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning',
      })
        .then(() => {
          this.memberTopUpMoney(row.id)
          // this.$message({
          //   type: 'success',
          //   message: '充值成功!'
          // });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消充值',
          })
          this.topUpMoney = ''
        })
    },
    // 新增消费记录
    addConsumeRecord() {
      this.$refs.addConsumeFromRef.validate(async valid => {
        if (!valid) return
        try {
          this.consumeRecordFrom.consumeDate = this.$nowDataFormet()
          const { id } = this.addConsumeMemberInfo
          const params = {
            id,
            consumeRecord: this.consumeRecordFrom,
          }
          const { data: res } = await this.$POST(apis.member.addConsumeRecord, params)
          const { code, message } = res
          if (code !== 200 && message !== '成功')
            return this.$notify.error({ title: '失败', message })
          this.$notify.success({ message: '划卡成功', title: '成功' })
          this.getMemberList()
          this.addConsumeDialogVisible = false
        } catch (error) {
          console.log(error)
        }
      })
    },
    // 展示划卡对话框
    showConsumeRecord(row) {
      this.addConsumeMemberInfo = row
      this.addConsumeDialogVisible = true
    },
    // 展示编辑会员抽屉
    showEditDrawer(row) {
      this.activeEditMemberInfo = row
      this.editDrawerStatus = true
    },
    // 编辑会员信息
    async editMemberInfo() {
      this.$refs.editMemberFromRef.validate(async valid => {
        if (!valid) return
        try {
          const { id, userName, createTime, phoneNum, type } = this.activeEditMemberInfo
          const params = {
            id,
            editData: {
              userName,
              createTime,
              phoneNum,
              type,
            },
          }
          const { data: res } = await this.$PUT(apis.member.editMember, params)
          console.log(res)
          const { code, message } = res
          if (code !== 200 && message !== '成功')
            return this.$notify.error({ message, title: '失败' })
          this.$notify.success({ message: '修改会员信息成功', title: '成功' })
          // this.getMemberList()
          this.changeCreateTime = ''
          this.editDrawerStatus = false
        } catch (error) {
          console.log(error)
        }
      })
    },
    // 新增会员
    addMember() {
      this.$refs.addMemberFromRef.validate(async valid => {
        if (!valid) return
        try {
          const { userName, createTime, phoneNum, money } = this.addMemberFrom
          const params = {
            userName,
            createTime: createTime || this.$nowDataFormet(),
            phoneNum,
            money,
            balance: money,
            consumeList: [],
            type: 1,
          }
          console.log('params', params)
          const { data: res } = await this.$POST(apis.member.addMember, params)
          console.log(res)
          const { code, message } = res
          if (code !== 200 && message !== '成功')
            return this.$notify.error({
              title: '失败',
              message: '新增会员失败!',
            })
          this.$notify.success({ title: '成功', message: '新增会员成功!' })
          this.getMemberList()
          this.addMemberDialogVisible = false
        } catch (error) {
          console.log(error)
        }
      })
    },
    // 重置表单
    resetFrom(ref) {
      this.addMemberFrom = {
        userName: '',
        createTime: '',
        phoneNum: 0 || '',
        money: 0 || '',
        balance: 0 || '',
        consumeList: [],
      }
      // this.addMemberFrom
      this.$refs[ref].resetFields()
    },
    // 获取表格列表
    async getMemberList() {
      this.isSearchTab = false
      try {
        const params = {
          id: this.$store.state.userInfo.id,
          queryStr: this.queryStr,
          page: this.page,
          pageSize: this.queryStr ? 99999 : this.pageSize,
        }
        const { data: res } = await this.$GET(apis.member.getMemberList, params)
        const { code, total, list, message } = res
        if (code !== 200 && message !== '成功') return
        list.forEach(item => (item.consumeCount = item.consumeList.length))
        this.total = total
        this.tableData = list
      } catch (error) {
        console.log(error)
      }
    },
    // 行折展处理
    handlExpandChange(row, expanded) {
      // console.log('row', row)
      // console.log('expanded', expanded)
      // this.$refs.memberListRef.toggleRowExpansion(row, true)
    },
    handleSizeChange(newPageSize) {
      window.localStorage.setItem('pageSize', newPageSize)
      this.page = 1
      this.pageSize = newPageSize
      this.getMemberList()
    },
    handleCurrentChange(newPage) {
      this.page = newPage
      this.getMemberList()
    },
    // 搜索
    async search() {
      if (!this.queryStr.length) return
      this.handleInitPageInfo()
      await this.getMemberList()
      this.$notify({
        title: '成功',
        message: `共找到 <b style="font-size: 16px">${this.tableData.length}</b>  条相关的数据`,
        type: 'success',
        duration: 1888,
        dangerouslyUseHTMLString: true,
      })
      this.isSearchTab = true
      // 符合结果高亮
      // let tablestr = document.getElementById('memberList').innerHTML
      // console.log('前', tablestr)
      // document.getElementById('memberList').innerHTML = tablestr.replaceAll(`${this.queryStr}`, `<span style="color: red">${this.queryStr}</span>`)
      // console.log('后', tablestr)
    },
    handleClose() {
      this.addConsumeDialogVisible = false
      this.addMemberDialogVisible = false
      this.editDrawerStatus = false
    },
    changeDate(val) {
      // console.log('时间变化', val)
      this.activeEditMemberInfo.createTime = val
    },
    handleConsumePoj() {
      this.$forceUpdate()
    },
    filterTag() {},
    handleInitPageInfo() {
      this.page = 1
      this.pageSize = 10
    },
  },
  watch: {
    queryStr: function (newVal) {
      if (newVal === '') this.getMemberList()
    },
    addMemberDialogVisible: function (newVal) {
      if (!newVal) this.resetFrom('addMemberFromRef')
    },
    // 筛选消费项目
    consumePojList: function (newVal) {
      if (!newVal.length) return
      this.consumeRecordFrom.consumePoj = newVal + ''
      this.$refs.addConsumeFromRef.validateField('consumePoj')
    },
    // 监听选择消费项目方式
    recordConsumeStatus: function (newStatus) {
      this.$refs.addConsumeFromRef.resetFields()
      if (newStatus) {
        // let list = this.consumeRecordFrom.consumePoj.split(',')
        this.consumePojList = []
      }
    },
    // 关闭划卡对话框
    addConsumeDialogVisible: function (val) {
      if (!val) {
        this.$refs.addConsumeFromRef.resetFields()
        this.consumePojList = []
        this.consumeRecordFrom = {
          consumePoj: '',
          consumeMoney: 0 || '',
          consumeDate: '',
          consumeNotes: '',
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.el-select {
  width: 100%;
}
/deep/.iconfont {
  font-size: 12px;
  margin-right: 5px;
}
/deep/.el-descriptions {
  width: 80%;
  margin: auto;
  border-bottom: 1px solid;
  margin-bottom: 5px;
}

/deep/.el-descriptions-item__content {
  width: 80%;
}
/deep/.el-descriptions-item__container {
  justify-content: flex-start;
  align-items: center;
  .el-descriptions-item__label.has-colon {
    color: gray;
    font-size: 16px;
    font-weight: 600;
  }
}
/deep/.el-dialog {
  border-radius: 10px;
}
/deep/.el-dialog--center .el-dialog__body {
  padding: 20px 50px;
}
/deep/.el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date {
  width: 100%;
}
.dataPickerBox {
  height: 24px;
  width: 0px;
  border: 0px;
  position: absolute;
  /deep/.el-input__inner {
    width: 0;
    height: 24px;
    margin: 0;
    padding: 0;
    border: none;
  }
  /deep/.el-input__prefix {
    left: -30px;
  }
}
.el-icon-date {
  width: 30px;
  height: 30px;
}
/deep/.el-descriptions-row {
  white-space: nowrap;
}
/deep/.el-table.td.el-table__cell.div,
/deep/.cell {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/deep/.el-button.el-button--success.el-button--mini.is-round {
  margin: 0;
}
/deep/.el-table__column-filter-trigger {
  display: none;
}
</style>
