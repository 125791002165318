const apis = {
  login: {
    // 登录
    userLogin: '/login/userLogin',
  },
  member: {
    // 获取会员列表
    getMemberList: '/member/list',
    // 会员下拉筛选数据
    userOptions: '/member/userOptions',
    // 新增会员
    addMember: '/member/addMember',
    // 编辑会员信息
    editMember: '/member/editMember',
    // 新增消费记录
    addConsumeRecord: '/member/addConsumeRecord',
    // 会员充值
    memberTopUp: '/member/memberTopUp',
    // 删除会员
    deleteMember: '/member/deleteMember',
    // 消费记录列表
    consumerRecord: '/member/consumerRecord',
  }
}

export default apis